import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputWithDropdown, Loader } from '../../components';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import { SelectionRange, initialSelectionRange } from '../../components/DateIntervalPicker';
import {
  fetchAllActivityReport,
  getReportsByRestaurant,
  getRestaurantActivityReport,
  getRestaurantActivityXlsx,
  getUserActivityXlsx,
  getUsersActivityReport,
  resetRestaurantActivity,
  resetUsersActivity,
} from '../../redux/reports/reports.actions';
import ReportInterval from '../NewRegistrationsReportScreen/subcomponents/ReportInterval';
import ActionButtons from '../NewRegistrationsReportScreen/subcomponents/ActionButtons';
import ReportParameters from '../NewRegistrationsReportScreen/subcomponents/ReportParameters';
import ComplexTable from '../../components/ComplexTable';
import { useLoadReportParams } from '../../hooks/useLoadReportParams';
import { ReportTypeEnum } from '../../redux/reports/interfaces';
import useDateConversion from '../../hooks/useDateConversion';
import { getAllRestaurants } from '../../redux/restaurants/restaurants.actions';
import { allActivityJsonToXlsx } from '../../utilities/functions/xlsExportHelper';
import ActivityAll from './subcomponents/ActivityAll';
import HeaderStatistic from './subcomponents/HeaderStatistic';

enum ActivityTypeEnum {
  ALL = 'all',
  RESTAURANTS = 'restaurants',
  USERS = 'users',
}

const ActivityReportScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const {
    activityAll,
    activityRestaurant,
    activityUser,
    numberOfRegisteredProviders,
    numberOfRegisteredUsers,
    loadingXls,
    restaurants,
    selectedRestaurantReport,
  } = useSelector((state: AppState) => ({
    activityAll: state.reports.activity.all,
    activityRestaurant: state.reports.activity.restaurant,
    activityUser: state.reports.activity.user,
    numberOfRegisteredProviders: state.reports.activity.restaurantCount,
    numberOfRegisteredUsers: state.reports.activity.numberOfRegisteredUsers,
    loadingXls: state.reports.loadingXls,
    restaurants: state.restaurants.restaurants,
    selectedRestaurantReport: state.reports.activityByRestaurant,
  }));
  const [isLoading, parameters] = useLoadReportParams(ReportTypeEnum.ACTIVITY);
  const [type, setType] = useState<ActivityTypeEnum>(ActivityTypeEnum.ALL);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>('');
  const [dateRange, setDateRange] = useState<SelectionRange[]>(initialSelectionRange);
  const [from, to] = useDateConversion(dateRange);

  useEffect(() => {
    type === ActivityTypeEnum.RESTAURANTS &&
      dispatch(getReportsByRestaurant(from, to, selectedRestaurantId));
  }, [dispatch, from, selectedRestaurantId, to]);

  useEffect(() => {
    dispatch(fetchAllActivityReport(from, to));
    dispatch(getAllRestaurants());
    // The selection must be a range
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, to, type]);

  useEffect(() => {
    if (type === ActivityTypeEnum.RESTAURANTS) {
      dispatch(getRestaurantActivityReport(from, to, page));
      dispatch(getAllRestaurants());
    } else if (type === ActivityTypeEnum.USERS) {
      dispatch(getUsersActivityReport(from, to, page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(resetRestaurantActivity(from, to));
    dispatch(resetUsersActivity(from, to));
    page > 0 && setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, to, type]);

  const handleNextPage = (): void => {
    setPage(page + 1);
  };
  const handlePrevPage = (): void => {
    setPage(page - 1);
  };

  const handleRestaurantXlsDownload = (): void => {
    dispatch(getRestaurantActivityXlsx(from, to));
  };

  const handleAllXlsDownload = (): void => {
    allActivityJsonToXlsx(from, to, activityAll);
  };

  const handleUsersXlsDownload = (): void => {
    dispatch(getUserActivityXlsx(from, to));
  };

  const nextPageFunctions = {
    [ActivityTypeEnum.ALL]: handleAllXlsDownload,
    [ActivityTypeEnum.USERS]: handleNextPage,
    [ActivityTypeEnum.RESTAURANTS]: handleNextPage,
  };

  const prevPageFunctions = {
    [ActivityTypeEnum.ALL]: handleAllXlsDownload,
    [ActivityTypeEnum.USERS]: handlePrevPage,
    [ActivityTypeEnum.RESTAURANTS]: handlePrevPage,
  };

  const xlsFunctions = {
    [ActivityTypeEnum.ALL]: handleAllXlsDownload,
    [ActivityTypeEnum.RESTAURANTS]: handleRestaurantXlsDownload,
    [ActivityTypeEnum.USERS]: handleUsersXlsDownload,
  };

  const renderHeaderStatistic = (): ReactElement | null => {
    switch (type) {
      case ActivityTypeEnum.RESTAURANTS:
        return (
          <HeaderStatistic
            labelKey="activityReportScreen.numberOfRegisteredProviders"
            value={numberOfRegisteredProviders}
          />
        );
      case ActivityTypeEnum.USERS:
        return (
          <HeaderStatistic
            labelKey="activityReportScreen.numberOfRegisteredUsers"
            value={numberOfRegisteredUsers}
          />
        );
      default:
        return null;
    }
  };

  const renderReport = (): ReactElement => {
    if (type === ActivityTypeEnum.ALL) {
      return <ActivityAll data={activityAll} />;
    }
    const tableData =
      type === ActivityTypeEnum.RESTAURANTS
        ? selectedRestaurantReport || activityRestaurant[0]
        : activityUser[page] || activityUser[0];
    const dataCount =
      type === ActivityTypeEnum.RESTAURANTS ? numberOfRegisteredProviders : numberOfRegisteredUsers;
    return (
      tableData && (
        <div className="activityTableContainer">
          {type === ActivityTypeEnum.RESTAURANTS ? (
            <div>
              <InputWithDropdown
                dropdownContent={restaurants.map((restaurant) => ({
                  label: restaurant.name || '',
                  value: restaurant._id || '',
                }))}
                multiple={false}
                placeholder="Please select a restaurant"
                value={[selectedRestaurantId]}
                className="is-small is-fullwidth"
                onChange={(value): void => {
                  setSelectedRestaurantId(value[0]);
                }}
              />
              <div>{`${t('activityReportScreen.makings')}: ${
                selectedRestaurantReport.additionalData.sumMakings
              }`}</div>
              <div>{`${t('activityReportScreen.sumOrder')}: ${
                selectedRestaurantReport.additionalData.ordersCount
              }`}</div>
            </div>
          ) : (
            <div />
          )}
          {tableData.data && tableData.data.length && tableData.columns ? (
            <ComplexTable
              pageNumber={page}
              totalPages={Math.ceil(dataCount / 15)}
              nextPage={nextPageFunctions[type]}
              previousPage={prevPageFunctions[type]}
              data={tableData.data}
              columns={tableData.columns}
            />
          ) : null}
        </div>
      )
    );
  };

  const renderData = (): ReactElement => (
    <>
      <div className="header">
        <div>{t('activityReportScreen.title')}</div>
        {renderHeaderStatistic()}
      </div>
      <div className="row">
        <div className="column">
          <ReportParameters
            data={parameters.activity}
            onRadioChange={(value: string): unknown => setType(value as ActivityTypeEnum)}
          />
          <ReportInterval dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        <div className="column">{renderReport()}</div>
      </div>
      <ActionButtons onXlsClick={xlsFunctions[type]} loadingXls={loadingXls} />
    </>
  );
  return (
    <div className="container">
      <div className="box activityReportScreenContainer">
        <Loader isLoading={isLoading} />
        {renderData()}
      </div>
    </div>
  );
};

export default withErrorMessage(ActivityReportScreen);
