import { jsPDF } from 'jspdf';
import i18next from 'i18next';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
import { IcomplaintType } from '../../redux/complaints/interfaces';
import { STATUS } from './../../resources/enums';
import { Iaddress } from './../../redux/restaurants/interfaces';

const getFileName = (id: string, from: number, to: number): string => {
  return `${id}${moment.unix(from).format('YYYY-MM-DD')}to${moment.unix(to).format('YYYY-MM-DD')}`;
};

/**
 *
 * @param address Address
 *
 * Converts an address object to string.
 */
export const addressToString = (address: Iaddress): string => {
  const { country, postalCode, city, streetName, streetNumber } = address;
  return `${country} ${postalCode} ${city}, ${streetName} ${streetNumber}`;
};
/**
 *
 * @param status STATUS
 *
 * Converts the status to string, with translation.
 */
export const statusToString = (status: STATUS): string => {
  return status === STATUS.ACTIVE ? i18next.t('ACTIVE') : i18next.t('INACTIVE');
};
/**
 *
 * @param status STATUS
 *
 * Converts the status to string for complaint, with translation.
 */
export const statusToStringForComplaint = (status: STATUS): string => {
  return status === STATUS.ACTIVE ? i18next.t('ACTIVE') : i18next.t('complaintListScreen.closed');
};

/**
 *
 * @param value - The complaint type's value.
 * @param complaintTypes - The complaint type lists.
 *
 * Return a complaint type's label by its value.
 */
export const getComplaintTypeLabelByValue = (
  value: string,
  complaintTypes: IcomplaintType[],
): string | undefined => {
  const complaintType =
    complaintTypes && complaintTypes.find((complaintType) => complaintType.value === value);
  return complaintType?.label;
};

/**
 * @param counrties - The country list.
 *
 * Move Hungary to the first place in the array of countries.
 */
export const moveHungaryToFirstPlaceInCountryList = (
  counrties: { label: string; value: string }[],
): { label: string; value: string }[] => {
  const hungaryElement = counrties.find((country) => country.value === 'Magyarország');
  const newList = counrties.filter((country) => country !== hungaryElement);

  if (hungaryElement) {
    newList.unshift(hungaryElement);
  }

  return newList;
};

/**
 *
 * @param id - Used in the filename be unique AND talkative what it is
 * @param from - timestamp
 * @param to - timestamp
 */
export const generatePdf = async (id: string, from: number, to: number): Promise<void> => {
  // Get the element by id should be ALWAYS available, but TS whining what if not...
  const input = window.document.getElementById(id) || document.createElement('<br>');
  const filename = getFileName(id, from, to);
  const width = input.clientWidth;
  const height = input.clientHeight;
  //New PDF page with custom dimensions
  const pdf = new jsPDF({
    unit: 'px',
    format: [width + 40, height + 40],
    orientation: width > height ? 'l' : 'p',
    compress: true,
  });
  // Convert the page to PNG
  const dataUrl = await htmlToImage.toPng(input);
  const img = new Image();
  img.src = dataUrl;
  pdf.addImage(img, 'PNG', 20, 20, width, height, 'NONE');
  pdf.save(`${filename}.pdf`);
};
