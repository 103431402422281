import { AxiosError } from 'axios';
import {
  InewRegistrationsReportResponse,
  IactivityReportResponse,
  IcomplaintsReportResponse,
  IreportParametersResponse,
  IfoodParametersReportResponse,
  IactivityRestaurantPayload,
  ActivityAllItem,
  IactivityUsersPayload,
} from './interfaces';

export const GET_REPORT_PARAMETERS_REQUEST = 'GET_REPORT_PARAMETERS_REQUEST';
export const GET_REPORT_PARAMETERS_SUCCESS = 'GET_REPORT_PARAMETERS_SUCCESS';
export const GET_REPORT_PARAMETERS_FAILURE = 'GET_REPORT_PARAMETERS_FAILURE';

export const GET_NEW_REGISTRATIONS_REPORT_REQUEST = 'GET_NEW_REGISTRATIONS_REPORT_REQUEST';
export const GET_NEW_REGISTRATIONS_REPORT_SUCCESS = 'GET_NEW_REGISTRATIONS_REPORT_SUCCESS';
export const GET_NEW_REGISTRATIONS_REPORT_FAILURE = 'GET_NEW_REGISTRATIONS_REPORT_FAILURE';

export const GET_ACTIVITY_REPORT_REQUEST = 'GET_ACTIVITY_REPORT_REQUEST';
export const GET_ACTIVITY_REPORT_SUCCESS = 'GET_ACTIVITY_REPORT_SUCCESS';
export const GET_ACTIVITY_REPORT_FAILURE = 'GET_ACTIVITY_REPORT_FAILURE';

export const GET_COMPLAINTS_REPORT_REQUEST = 'GET_COMPLAINTS_REPORT_REQUEST';
export const GET_COMPLAINTS_REPORT_SUCCESS = 'GET_COMPLAINTS_REPORT_SUCCESS';
export const GET_COMPLAINTS_REPORT_FAILURE = 'GET_COMPLAINTS_REPORT_FAILURE';

export const GET_FOOD_PARAMETERS_REPORT_REQUEST = 'GET_FOOD_PARAMETERS_REPORT_REQUEST';
export const GET_FOOD_PARAMETERS_REPORT_SUCCESS = 'GET_FOOD_PARAMETERS_REPORT_SUCCESS';
export const GET_FOOD_PARAMETERS_REPORT_FAILURE = 'GET_FOOD_PARAMETERS_REPORT_FAILURE';

export const GET_RESTAURANT_ACTIVITY_REPORT_REQUEST = 'GET_RESTAURANT_ACTIVITY_REPORT_REQUEST';
export const GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS = 'GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS';
export const GET_RESTAURANT_ACTIVITY_REPORT_FAILURE = 'GET_RESTAURANT_ACTIVITY_REPORT_FAILURE';

export const GET_USERS_ACTIVITY_REPORT_REQUEST = 'GET_USERS_ACTIVITY_REPORT_REQUEST';
export const GET_USERS_ACTIVITY_REPORT_SUCCESS = 'GET_USERS_ACTIVITY_REPORT_SUCCESS';
export const GET_USERS_ACTIVITY_REPORT_FAILURE = 'GET_USERS_ACTIVITY_REPORT_FAILURE';

export const GET_ALL_ACTIVITY_REPORT_REQUEST = 'GET_ALL_ACTIVITY_REPORT_REQUEST';
export const GET_ALL_ACTIVITY_REPORT_SUCCESS = 'GET_ALL_ACTIVITY_REPORT_SUCCESS';
export const GET_ALL_ACTIVITY_REPORT_FAILURE = 'GET_ALL_ACTIVITY_REPORT_FAILURE';

export const GET_RESTAURANT_ACTIVITY_XLSX_REQUEST = 'GET_RESTAURANT_ACTIVITY_XLSX_REQUEST';
export const GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS = 'GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS';
export const GET_RESTAURANT_ACTIVITY_XLSX_FAILURE = 'GET_RESTAURANT_ACTIVITY_XLSX_FAILURE';

export const GET_NEW_REGISTRATIONS_XLSX_REQUEST = 'GET_NEW_REGISTRATIONS_XLSX_REQUEST';
export const GET_NEW_REGISTRATIONS_XLSX_SUCCESS = 'GET_NEW_REGISTRATIONS_XLSX_SUCCESS';
export const GET_NEW_REGISTRATIONS_XLSX_FAILURE = 'GET_NEW_REGISTRATIONS_XLSX_FAILURE';

export const GET_COMPLAINTS_XLSX_REQUEST = 'GET_COMPLAINTS_XLSX_REQUEST';
export const GET_COMPLAINTS_XLSX_SUCCESS = 'GET_COMPLAINTS_XLSX_SUCCESS';
export const GET_COMPLAINTS_XLSX_FAILURE = 'GET_COMPLAINTS_XLSX_FAILURE';

export const GET_PDF_REQUEST = 'GET_PDF_REQUEST';
export const GET_PDF_SUCCESS = 'GET_PDF_SUCCESS';
export const GET_PDF_FAILURE = 'GET_NEW_REGISTRATIONS_PDF_FAILURE';

export const RESET_RESTAURANT_ACTIVITY_LIST = 'RESET_RESTAURANT_ACTIVITY_LIST';
export const RESET_USERS_ACTIVITY_LIST = 'RESET_USERS_ACTIVITY_LIST';

export const ACTIVITY_BY_RESTAURANT_REQUEST = 'ACTIVITY_BY_RESTAURANT_REQUEST';
export const ACTIVITY_BY_RESTAURANT_SUCCESS = 'ACTIVITY_BY_RESTAURANT_SUCCESS';
export const ACTIVITY_BY_RESTAURANT_FAILURE = 'ACTIVITY_BY_RESTAURANT_FAILURE';

export interface IgetActivityByRestaurantRequest {
  type: typeof ACTIVITY_BY_RESTAURANT_REQUEST;
}
export interface IgetActivityByRestaurantSuccess {
  type: typeof ACTIVITY_BY_RESTAURANT_SUCCESS;
  payload: any;
}
export interface IgetActivityByRestaurantFailure {
  type: typeof ACTIVITY_BY_RESTAURANT_FAILURE;
  error: AxiosError;
}
export const GET_USER_ACTIVITY_XLSX_REQUEST = 'GET_USER_ACTIVITY_XLSX_REQUEST';
export const GET_USER_ACTIVITY_XLSX_SUCCESS = 'GET_USER_ACTIVITY_XLSX_SUCCESS';
export const GET_USER_ACTIVITY_XLSX_FAILURE = 'GET_USER_ACTIVITY_XLSX_FAILURE';

export interface IresetRestaurantActivityList {
  type: typeof RESET_RESTAURANT_ACTIVITY_LIST;
}
export interface IresetUsersActivityList {
  type: typeof RESET_USERS_ACTIVITY_LIST;
}

export interface IgetRestaurantActivityXlsxRequest {
  type: typeof GET_RESTAURANT_ACTIVITY_XLSX_REQUEST;
}
export interface IgetRestaurantActivityXlsxSuccess {
  type: typeof GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS;
}
export interface IgetRestaurantActivityXlsxFailure {
  type: typeof GET_RESTAURANT_ACTIVITY_XLSX_FAILURE;
  error: AxiosError;
}

export interface IgetUserActivityXlsxRequest {
  type: typeof GET_USER_ACTIVITY_XLSX_REQUEST;
}
export interface IgetUserActivityXlsxSuccess {
  type: typeof GET_USER_ACTIVITY_XLSX_SUCCESS;
}
export interface IgetUserActivityXlsxFailure {
  type: typeof GET_USER_ACTIVITY_XLSX_FAILURE;
  error: AxiosError;
}

export interface IgetNewRegistrationsXlsxRequest {
  type: typeof GET_NEW_REGISTRATIONS_XLSX_REQUEST;
}
export interface IgetNewRegistrationsXlsxSuccess {
  type: typeof GET_NEW_REGISTRATIONS_XLSX_SUCCESS;
}
export interface IgetNewRegistrationsXlsxFailure {
  type: typeof GET_NEW_REGISTRATIONS_XLSX_FAILURE;
  error: AxiosError;
}

export interface IgetComplaintsXlsxRequest {
  type: typeof GET_COMPLAINTS_XLSX_REQUEST;
}
export interface IgetComplaintsXlsxSuccess {
  type: typeof GET_COMPLAINTS_XLSX_SUCCESS;
}
export interface IgetComplaintsXlsxFailure {
  type: typeof GET_COMPLAINTS_XLSX_FAILURE;
  error: AxiosError;
}

export interface IgetRestaurantActivityReportRequest {
  type: typeof GET_RESTAURANT_ACTIVITY_REPORT_REQUEST;
}

export interface IgetRestaurantActivityReportSuccess {
  type: typeof GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS;
  payload: IactivityRestaurantPayload;
}

export interface IgetRestaurantActivityReportFailure {
  type: typeof GET_RESTAURANT_ACTIVITY_REPORT_FAILURE;
  error: AxiosError;
}
export interface IgetUsersActivityReportRequest {
  type: typeof GET_USERS_ACTIVITY_REPORT_REQUEST;
}

export interface IgetUsersActivityReportSuccess {
  type: typeof GET_USERS_ACTIVITY_REPORT_SUCCESS;
  payload: IactivityUsersPayload;
}

export interface IgetUsersActivityReportFailure {
  type: typeof GET_USERS_ACTIVITY_REPORT_FAILURE;
  error: AxiosError;
}
export interface IgetReportParametersRequest {
  type: typeof GET_REPORT_PARAMETERS_REQUEST;
}
export interface IgetReportParametersSuccess {
  type: typeof GET_REPORT_PARAMETERS_SUCCESS;
  payload: IreportParametersResponse;
}
export interface IgetReportParametersFailure {
  type: typeof GET_REPORT_PARAMETERS_FAILURE;
  error: AxiosError;
}

export interface IgetNewRegistrationsReportRequest {
  type: typeof GET_NEW_REGISTRATIONS_REPORT_REQUEST;
}
export interface IgetNewRegistrationsReportSuccess {
  type: typeof GET_NEW_REGISTRATIONS_REPORT_SUCCESS;
  payload: InewRegistrationsReportResponse;
}
export interface IgetNewRegistrationsReportFailure {
  type: typeof GET_NEW_REGISTRATIONS_REPORT_FAILURE;
  error: AxiosError;
}

export interface IgetActivityReportRequest {
  type: typeof GET_ACTIVITY_REPORT_REQUEST;
}
export interface IgetActivityReportSuccess {
  type: typeof GET_ACTIVITY_REPORT_SUCCESS;
  payload: IactivityReportResponse;
}
export interface IgetActivityReportFailure {
  type: typeof GET_ACTIVITY_REPORT_FAILURE;
  error: AxiosError;
}

export interface IgetComplaintsReportRequest {
  type: typeof GET_COMPLAINTS_REPORT_REQUEST;
}
export interface IgetComplaintsReportSuccess {
  type: typeof GET_COMPLAINTS_REPORT_SUCCESS;
  payload: IcomplaintsReportResponse;
}
export interface IgetComplaintsReportFailure {
  type: typeof GET_COMPLAINTS_REPORT_FAILURE;
  error: AxiosError;
}

export interface IgetAllActivityRequest {
  type: typeof GET_ALL_ACTIVITY_REPORT_REQUEST;
}
export interface IgetAllActivitySuccess {
  type: typeof GET_ALL_ACTIVITY_REPORT_SUCCESS;
  payload: ActivityAllItem[];
}
export interface IgetAllActivityFailure {
  type: typeof GET_ALL_ACTIVITY_REPORT_FAILURE;
  error: AxiosError;
}
export interface IgetFoodParametersReportRequest {
  type: typeof GET_FOOD_PARAMETERS_REPORT_REQUEST;
}
export interface IgetFoodParametersReportSuccess {
  type: typeof GET_FOOD_PARAMETERS_REPORT_SUCCESS;
  payload: IfoodParametersReportResponse;
}
export interface IgetFoodParametersReportFailure {
  type: typeof GET_FOOD_PARAMETERS_REPORT_FAILURE;
  error: AxiosError;
}
export interface IgetPdfRequest {
  type: typeof GET_PDF_REQUEST;
}
export interface IgetPdfSuccess {
  type: typeof GET_PDF_SUCCESS;
}
export interface IgetPdfFailure {
  type: typeof GET_PDF_FAILURE;
  error: AxiosError;
}

export type ReportsAsctionType =
  | IgetReportParametersRequest
  | IgetReportParametersSuccess
  | IgetReportParametersFailure
  | IgetNewRegistrationsReportRequest
  | IgetNewRegistrationsReportSuccess
  | IgetNewRegistrationsReportFailure
  | IgetActivityReportRequest
  | IgetActivityReportSuccess
  | IgetActivityReportFailure
  | IgetComplaintsReportRequest
  | IgetComplaintsReportSuccess
  | IgetComplaintsReportFailure
  | IgetFoodParametersReportRequest
  | IgetFoodParametersReportSuccess
  | IgetFoodParametersReportFailure
  | IgetRestaurantActivityReportFailure
  | IgetRestaurantActivityReportRequest
  | IgetRestaurantActivityReportSuccess
  | IgetRestaurantActivityXlsxFailure
  | IgetRestaurantActivityXlsxRequest
  | IgetRestaurantActivityXlsxSuccess
  | IgetNewRegistrationsXlsxFailure
  | IgetNewRegistrationsXlsxRequest
  | IgetNewRegistrationsXlsxSuccess
  | IgetComplaintsXlsxFailure
  | IgetComplaintsXlsxRequest
  | IgetComplaintsXlsxSuccess
  | IresetRestaurantActivityList
  | IgetAllActivityRequest
  | IgetAllActivitySuccess
  | IgetAllActivityFailure
  | IgetUsersActivityReportRequest
  | IgetUsersActivityReportSuccess
  | IgetUsersActivityReportFailure
  | IresetUsersActivityList
  | IgetActivityByRestaurantFailure
  | IgetActivityByRestaurantRequest
  | IgetActivityByRestaurantSuccess
  | IgetUserActivityXlsxRequest
  | IgetUserActivityXlsxSuccess
  | IgetUserActivityXlsxFailure
  | IgetPdfRequest
  | IgetPdfSuccess
  | IgetPdfFailure;
