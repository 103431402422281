import exceljs from 'exceljs';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { WITH_VAT } from '../../resources/constants';
import {
  RegistrationXlsData,
  RestaurantXlsResponse,
  ComplaintXlsResponse,
  ReportParameter,
  ActivityAllItem,
  IactivityUserData,
} from '../../redux/reports/interfaces';
import { ChartCoordinate } from '../../resources/interfaces';

const getFileName = (from: number, to: number, reportType: string): string => {
  return `${reportType}${moment.unix(from).format('YYYY-MM-DD')}to${moment
    .unix(to)
    .format('YYYY-MM-DD')}`;
};

export const restaurantJsonToXlsx = async (
  from: number,
  to: number,
  data: RestaurantXlsResponse[],
): Promise<void> => {
  const fileName = getFileName(from, to, 'RestaurantActivity');

  const columns = [
    { name: 'Tétel' },
    { name: 'Időpont' },
    { name: 'Vásárló neve' },
    { name: 'Tétel értéke' },
    { name: 'Darabszám' },
  ];

  const secondaryColumns = [
    { name: 'Tételek száma' },
    { name: 'Összes forgalom (HUF)' },
    { name: 'Jutalék összege (+ÁFA)' },
    { name: 'Összes forgalom a jutalék levonása után' },
  ];

  const workbook = new exceljs.Workbook();

  // prevent xls file from breaking
  if (data.length === 0) {
    workbook.addWorksheet('');
  }

  data.forEach((restaurant) => {
    const { name: restaurantName, orders, dividendPercent, _id: restaurantId } = restaurant;

    // Create Sheet per restaurant

    const sheet = workbook.addWorksheet(restaurantName);
    sheet.addTable({
      name: 'Adatok',
      ref: 'A1',
      style: { theme: 'TableStyleLight1' },
      columns: [{ name: 'Éttermi azonosító' }, { name: 'Étterem neve' }],
      rows: [[restaurantId, restaurantName]],
    });

    // Populate sheet with data
    const sheetData = orders.map((order) => {
      const userName = order.userName;
      const paymentDate = moment(order.paymentDate).format('YYYY.MM.DD');
      return order.cart.map((cartItem) => {
        return {
          productName: cartItem.name,
          paymentDate,
          userName,
          price: cartItem.price.discountPrice,
          quantity: cartItem.quantity,
        };
      });
    });

    const detailedOffers = sheetData.map((da) => da.map((d) => Object.values(d)));
    const flatDetailedOffers = detailedOffers.flatMap((offer, index, origi) =>
      offer.length > 1 ? offer : [...origi[index]],
    );

    sheet.addTable({
      name: 'Tételes',
      ref: 'A4',
      style: { theme: 'TableStyleLight1' },
      columns: columns,
      rows: flatDetailedOffers,
    });

    //Calculate sum values
    const productAmount = flatDetailedOffers.length;
    const totalPrices = sheetData.flat().reduce((sum, i) => {
      return sum + i.price * i.quantity;
    }, 0);
    const dividend = Math.round(totalPrices * dividendPercent * WITH_VAT);
    const sumMakingsAfterDividend = totalPrices - dividend;

    // Add table to the existing sheet with sum values
    sheet.addTable({
      name: 'Összegzés',
      ref: sheet.getRow(flatDetailedOffers.length + 6).getCell(1).address,
      style: { theme: 'TableStyleLight1' },
      columns: secondaryColumns,
      rows: [[productAmount, totalPrices, dividend, sumMakingsAfterDividend]],
    });
  });

  workbook.creator = 'Tudatos Team';
  workbook.created = new Date();
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName + '.xlsx');
};

export const registrationJsonToXlsx = async (
  from: number,
  to: number,
  data: RegistrationXlsData,
): Promise<void> => {
  const fileName = getFileName(from, to, 'NewRegistrations');

  const workbook = new exceljs.Workbook();
  const sheet = workbook.addWorksheet('Regisztrációk');

  const restaurantTitleCell = sheet.getCell('B2');
  restaurantTitleCell.value = 'Éttermi regisztrációk';

  const userTitleCell = sheet.getCell('E2');
  userTitleCell.value = 'Felhasználói regisztrációk';

  sheet.addTable({
    name: 'Adatok',
    ref: 'B4',
    style: { theme: 'TableStyleLight1' },
    columns: [{ name: 'Dátum' }, { name: 'Regisztrációk száma' }],
    rows: data.restaurants.map((newRegs: ChartCoordinate) => [
      moment(newRegs.date).format('YYYY-MM-DD'),
      newRegs.y,
    ]),
  });

  sheet.addTable({
    name: 'Adatok',
    ref: 'E4',
    style: { theme: 'TableStyleLight1' },
    columns: [{ name: 'Dátum' }, { name: 'Regisztrációk száma' }],
    rows: data.users.map((newRegs: ChartCoordinate) => [
      moment(newRegs.date).format('YYYY-MM-DD'),
      newRegs.y,
    ]),
  });

  // Populate sheet with data

  workbook.creator = 'Tudatos Team';
  workbook.created = new Date();
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName + '.xlsx');
};

export const complaintJsonToXlsx = async (
  from: number,
  to: number,
  data: ComplaintXlsResponse[],
  reportParameters: ReportParameter[],
): Promise<void> => {
  const fileName = getFileName(from, to, 'Complaints');

  const workbook = new exceljs.Workbook();
  const sheet = workbook.addWorksheet('Panaszok');

  const restaurants = Array.from(
    new Set(data.map((d) => d.complaints.map((c) => c.restaurant)).flat()),
  );

  sheet.addTable({
    name: 'Adatok',
    ref: 'A1',
    style: { theme: 'TableStyleLight1' },
    columns: [{ name: 'Panasztípus' }, ...restaurants.map((r) => ({ name: r }))],
    rows: data.map((d) => {
      return [
        reportParameters.find((r) => r.id === d._id)?.label,
        ...restaurants.map((r) => {
          const complaintsForRestaurant = d.complaints.find((c) => c.restaurant === r);
          return complaintsForRestaurant ? complaintsForRestaurant.count : 0;
        }),
      ];
    }),
  });

  workbook.creator = 'Tudatos Team';
  workbook.created = new Date();
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName + '.xlsx');
};

export const allActivityJsonToXlsx = async (
  from: number,
  to: number,
  data: ActivityAllItem[],
): Promise<void> => {
  const fileName = getFileName(from, to, 'AllActivity');

  const workbook = new exceljs.Workbook();
  const sheet = workbook.addWorksheet('Panaszok');

  sheet.addTable({
    name: 'Adatok',
    ref: 'A1',
    style: { theme: 'TableStyleLight1' },
    columns: [{ name: 'Típus' }, { name: 'Érték' }],
    rows: data.map((d) => {
      return [d.label, d.value];
    }),
  });

  workbook.creator = 'Tudatos Team';
  workbook.created = new Date();
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName + '.xlsx');
};

export const userActivityJsonToXlsx = async (
  from: number,
  to: number,
  data: IactivityUserData[],
): Promise<void> => {
  const fileName = getFileName(from, to, 'UserActivity');

  const workbook = new exceljs.Workbook();
  const sheetActive = workbook.addWorksheet('Aktív felhasználók');
  const sheetInactive = workbook.addWorksheet('Inaktív felhasználók');

  const columns = [
    { name: 'Felhasználó neve' },
    { name: 'Sikeres vásárlások' },
    { name: 'Sikertelen vásárlások' },
    { name: 'Összes vásárlás' },
    { name: 'Forgalom (HUF)' },
  ];

  const getRow = (d: IactivityUserData): (number | string)[] => [
    d.userName,
    d.completedOrdersCount,
    d.failedOrdersCount,
    d.allOrdersCount,
    d.cashFlow,
  ];

  sheetActive.addTable({
    name: 'Adatok',
    ref: 'A1',
    style: { theme: 'TableStyleLight1' },
    columns,
    rows: data
      .filter((d) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userName, ...userData } = d;
        return Object.values(userData).some((value) => value);
      })
      .map(getRow),
  });

  sheetInactive.addTable({
    name: 'Adatok',
    ref: 'A1',
    style: { theme: 'TableStyleLight1' },
    columns,
    rows: data
      .filter((d) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { userName, ...userData } = d;
        return Object.values(userData).every((value) => !value);
      })
      .map(getRow),
  });

  workbook.creator = 'Tudatos Team';
  workbook.created = new Date();
  const buffer = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer]), fileName + '.xlsx');
};
