import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import { SelectionRange, initialSelectionRange } from '../../components/DateIntervalPicker';
import { InputWithDropdown, Loader } from '../../components';
import Chart from '../../components/Chart';
import ReportInterval from '../NewRegistrationsReportScreen/subcomponents/ReportInterval';
import ActionButtons from '../NewRegistrationsReportScreen/subcomponents/ActionButtons';
import ReportParameters from '../NewRegistrationsReportScreen/subcomponents/ReportParameters';
import {
  fetchComplaintsReport,
  getComplaintsXlsx,
  getPdf,
} from '../../redux/reports/reports.actions';
import { useLoadReportParams } from '../../hooks/useLoadReportParams';
import { ReportTypeEnum } from '../../redux/reports/interfaces';
import useDateConversion from '../../hooks/useDateConversion';
import useRestaurantNames from '../../hooks/useRestaurantNames';

const ComplaintsReportScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { complaints, loadingXls, loadingPdf } = useSelector((state: AppState) => state.reports);
  const [isLoading, parameters] = useLoadReportParams(ReportTypeEnum.COMPLAINTS);
  const [dateRange, setDateRange] = useState<SelectionRange[]>(initialSelectionRange);
  const [from, to] = useDateConversion(dateRange);
  const [restaurantNames] = useRestaurantNames();
  const [selectedRestaurant, setSelectedRestaurant] = useState(['']);
  const [form, setForm] = useState(parameters.complaints);

  useEffect(() => {
    setForm(parameters.complaints);
  }, [parameters]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setForm(
      [...form].map((item) =>
        item.id === e.target.name ? { ...item, checked: e.target.checked } : item,
      ),
    );
  };
  const handleDropdownChange = (value: string[]): void => setSelectedRestaurant(value);

  useEffect(() => {
    if (selectedRestaurant[0].length > 0) {
      dispatch(
        fetchComplaintsReport(
          from,
          to,
          form.filter((t) => t.checked).map((t) => t.id),
          selectedRestaurant[0],
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, from, to, form, selectedRestaurant]);

  const handleXls = (): void => {
    dispatch(getComplaintsXlsx(from, to));
  };

  const handlePdf = (): void => {
    dispatch(getPdf('ComplaintsReports', from, to));
  };

  const renderData = (): ReactElement => (
    <>
      <div id="ComplaintsReports">
        <div className="header">{t('complaintsReportScreen.title')}</div>
        <div className="column complaintsChartContainer">
          <Chart data={complaints} />
        </div>
        <InputWithDropdown
          className="complaintsReportDropdown"
          placeholder={t('reportsScreen.selectRestaurant')}
          onChange={handleDropdownChange}
          dropdownContent={restaurantNames}
          value={selectedRestaurant}
        />
        <div className="column chartSettingsContainer">
          <ReportParameters onCheckboxChange={handleCheckboxChange} data={form} />
          <ReportInterval dateRange={dateRange} setDateRange={setDateRange} />
        </div>
      </div>
      <ActionButtons
        onPdfClick={handlePdf}
        loadingPdf={loadingPdf}
        onXlsClick={handleXls}
        loadingXls={loadingXls}
      />
    </>
  );

  return (
    <div className="container">
      <div className="box complaintsReportScreenContainer">
        <Loader isLoading={isLoading} />
        {renderData()}
      </div>
    </div>
  );
};

export default withErrorMessage(ComplaintsReportScreen);
