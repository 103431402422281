import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../components';
import withErrorMessage from '../../utilities/HOC/withErrorMessage';
import { AppState } from '../../redux/store';
import Chart from '../../components/Chart';
import { SelectionRange, initialSelectionRange } from '../../components/DateIntervalPicker';
import {
  fetchNewRegistrationsReport,
  getNewRegistartionsXlsx,
  getPdf,
} from '../../redux/reports/reports.actions';
import { useLoadReportParams } from '../../hooks/useLoadReportParams';
import { ReportTypeEnum } from '../../redux/reports/interfaces';
import useDateConversion from '../../hooks/useDateConversion';
import { USERTYPE } from '../../resources/enums';
import ReportParameters from './subcomponents/ReportParameters';
import ReportInterval from './subcomponents/ReportInterval';
import ActionButtons from './subcomponents/ActionButtons';

const NewRegistrationsReportScreen: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newRegistrations, loadingXls, loadingPdf } = useSelector(
    (state: AppState) => state.reports,
  );
  const [isLoading, parameters] = useLoadReportParams(ReportTypeEnum.NEW_REGISTRATIONS);
  const [dateRange, setDateRange] = useState<SelectionRange[]>(initialSelectionRange);
  const [from, to] = useDateConversion(dateRange);
  const [selectedType, setSelectedType] = useState<USERTYPE | string>(USERTYPE.RESTAURANT);

  useEffect(() => {
    dispatch(fetchNewRegistrationsReport(from, to, selectedType));
  }, [dispatch, from, to, selectedType]);

  const handleRadioChange = (v: USERTYPE | string): unknown => setSelectedType(v);

  const handleXls = (): void => {
    dispatch(
      getNewRegistartionsXlsx(
        from,
        to,
        selectedType === USERTYPE.RESTAURANT ? USERTYPE.USER : USERTYPE.RESTAURANT,
      ),
    );
  };

  const handlePdf = (): void => {
    dispatch(getPdf('NewRegistrationsChart', from, to));
  };

  const renderData = (): ReactElement => (
    <>
      <div id="NewRegistrationsChart">
        <div className="header">{t('newRegistrationsReportScreen.title')}</div>
        <div className="column newRegistrationChartContainer">
          <Chart data={[{ data: newRegistrations }]} />
        </div>
        <div className="column chartSettingsContainer">
          <ReportParameters onRadioChange={handleRadioChange} data={parameters.newRegistrations} />
          <ReportInterval dateRange={dateRange} setDateRange={setDateRange} />
        </div>
      </div>
      <ActionButtons
        onPdfClick={handlePdf}
        loadingPdf={loadingPdf}
        onXlsClick={handleXls}
        loadingXls={loadingXls}
      />
    </>
  );

  return (
    <div className="container">
      <div className="box newRegistrationReportScreenContainer">
        <Loader isLoading={isLoading} />
        {renderData()}
      </div>
    </div>
  );
};

export default withErrorMessage(NewRegistrationsReportScreen);
