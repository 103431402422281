import {
  GET_NEW_REGISTRATIONS_REPORT_REQUEST,
  GET_NEW_REGISTRATIONS_REPORT_SUCCESS,
  GET_NEW_REGISTRATIONS_REPORT_FAILURE,
  GET_ACTIVITY_REPORT_REQUEST,
  GET_ACTIVITY_REPORT_FAILURE,
  GET_ACTIVITY_REPORT_SUCCESS,
  GET_COMPLAINTS_REPORT_REQUEST,
  GET_COMPLAINTS_REPORT_FAILURE,
  GET_COMPLAINTS_REPORT_SUCCESS,
  GET_REPORT_PARAMETERS_REQUEST,
  GET_REPORT_PARAMETERS_FAILURE,
  GET_REPORT_PARAMETERS_SUCCESS,
  GET_FOOD_PARAMETERS_REPORT_REQUEST,
  GET_FOOD_PARAMETERS_REPORT_SUCCESS,
  GET_FOOD_PARAMETERS_REPORT_FAILURE,
  GET_RESTAURANT_ACTIVITY_REPORT_REQUEST,
  GET_RESTAURANT_ACTIVITY_REPORT_FAILURE,
  GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS,
  GET_RESTAURANT_ACTIVITY_XLSX_REQUEST,
  GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS,
  GET_RESTAURANT_ACTIVITY_XLSX_FAILURE,
  GET_NEW_REGISTRATIONS_XLSX_REQUEST,
  GET_NEW_REGISTRATIONS_XLSX_SUCCESS,
  GET_NEW_REGISTRATIONS_XLSX_FAILURE,
  GET_COMPLAINTS_XLSX_REQUEST,
  GET_COMPLAINTS_XLSX_SUCCESS,
  GET_COMPLAINTS_XLSX_FAILURE,
  RESET_RESTAURANT_ACTIVITY_LIST,
  GET_ALL_ACTIVITY_REPORT_FAILURE,
  GET_ALL_ACTIVITY_REPORT_REQUEST,
  GET_ALL_ACTIVITY_REPORT_SUCCESS,
  GET_USERS_ACTIVITY_REPORT_FAILURE,
  GET_USERS_ACTIVITY_REPORT_REQUEST,
  GET_USERS_ACTIVITY_REPORT_SUCCESS,
  RESET_USERS_ACTIVITY_LIST,
  ACTIVITY_BY_RESTAURANT_REQUEST,
  ACTIVITY_BY_RESTAURANT_FAILURE,
  ACTIVITY_BY_RESTAURANT_SUCCESS,
  GET_USER_ACTIVITY_XLSX_SUCCESS,
  GET_USER_ACTIVITY_XLSX_FAILURE,
  GET_USER_ACTIVITY_XLSX_REQUEST,
  GET_PDF_REQUEST,
  GET_PDF_FAILURE,
  GET_PDF_SUCCESS,
} from './types';
import { AppAction } from './../apptypes';
import { IreportsState } from './interfaces';

const reportsDefaultState: IreportsState = {
  isLoading: false,
  loadingXls: false,
  loadingPdf: false,
  errorMessage: null,
  userNumber: 0,
  completedOffers: 0,
  totalCost: 0,
  parameters: {
    newRegistrations: [],
    activity: [],
    complaints: [],
  },
  newRegistrations: [],
  activity: {
    all: [],
    restaurant: [],
    isLastPage: false,
    restaurantCount: 0,
    user: [],
    numberOfRegisteredUsers: 0,
  },
  activityByRestaurant: {
    data: null,
    columns: null,
    additionalData: {
      sumMakings: 0,
      ordersCount: 0,
    },
  },
  complaints: [],
  foodParameters: {},
};

export default (state = reportsDefaultState, action: AppAction): IreportsState => {
  switch (action.type) {
    case RESET_RESTAURANT_ACTIVITY_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          isLastPage: false,
          restaurantCount: 0,
          restaurant: [],
        },
      };
    case RESET_USERS_ACTIVITY_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          isLastPage: false,
          numberOfRegisteredUsers: 0,
          user: [],
        },
      };
    case GET_RESTAURANT_ACTIVITY_XLSX_REQUEST:
    case GET_USER_ACTIVITY_XLSX_REQUEST:
    case GET_NEW_REGISTRATIONS_XLSX_REQUEST:
    case GET_COMPLAINTS_XLSX_REQUEST:
      return {
        ...state,
        errorMessage: null,
        loadingXls: true,
      };
    case GET_PDF_REQUEST:
      return {
        ...state,
        errorMessage: null,
        loadingPdf: true,
      };
    case GET_PDF_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loadingPdf: false,
      };
    case GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS:
    case GET_NEW_REGISTRATIONS_XLSX_SUCCESS:
    case GET_USER_ACTIVITY_XLSX_SUCCESS:
    case GET_COMPLAINTS_XLSX_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loadingXls: false,
      };
    case GET_REPORT_PARAMETERS_REQUEST:
    case GET_NEW_REGISTRATIONS_REPORT_REQUEST:
    case GET_ACTIVITY_REPORT_REQUEST:
    case GET_COMPLAINTS_REPORT_REQUEST:
    case GET_FOOD_PARAMETERS_REPORT_REQUEST:
    case GET_RESTAURANT_ACTIVITY_REPORT_REQUEST:
    case GET_ALL_ACTIVITY_REPORT_REQUEST:
    case GET_USERS_ACTIVITY_REPORT_REQUEST:
    case ACTIVITY_BY_RESTAURANT_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case GET_ALL_ACTIVITY_REPORT_FAILURE:
    case GET_REPORT_PARAMETERS_FAILURE:
    case GET_NEW_REGISTRATIONS_REPORT_FAILURE:
    case GET_ACTIVITY_REPORT_FAILURE:
    case GET_COMPLAINTS_REPORT_FAILURE:
    case GET_FOOD_PARAMETERS_REPORT_FAILURE:
    case GET_RESTAURANT_ACTIVITY_REPORT_FAILURE:
    case GET_RESTAURANT_ACTIVITY_XLSX_FAILURE:
    case GET_USER_ACTIVITY_XLSX_FAILURE:
    case GET_NEW_REGISTRATIONS_XLSX_FAILURE:
    case GET_COMPLAINTS_XLSX_FAILURE:
    case GET_USERS_ACTIVITY_REPORT_FAILURE:
    case GET_PDF_FAILURE:
      return {
        ...state,
        isLoading: false,
        loadingXls: false,
        loadingPdf: false,
        errorMessage: action.error.response?.data.message,
      };

    case GET_REPORT_PARAMETERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        parameters: action.payload.data,
      };
    case GET_NEW_REGISTRATIONS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        newRegistrations: action.payload.data,
      };
    case GET_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          ...action.payload.data,
        },
      };
    case GET_ALL_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          all: [...action.payload],
        },
      };
    case GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          isLastPage: action.payload.isLastPage,
          restaurantCount: action.payload.restaurantCount,
          restaurant: action.payload.restaurant,
        },
      };
    case ACTIVITY_BY_RESTAURANT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activityByRestaurant: action.payload,
      };
    }
    case GET_USERS_ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        activity: {
          ...state.activity,
          isLastPage: action.payload.isLastPage,
          numberOfRegisteredUsers:
            action.payload.numberOfRegisteredUsers || action.payload.user.length,
          user: action.payload.user,
        },
      };
    case GET_COMPLAINTS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        complaints: action.payload.data,
      };
    case GET_FOOD_PARAMETERS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        foodParameters: action.payload.data,
      };

    default:
      return state;
  }
};
