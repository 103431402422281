import { Dispatch } from 'redux';
import { defaultOrderByFn } from 'react-table';
import client from '../../api/client';
import {
  registrationJsonToXlsx,
  restaurantJsonToXlsx,
  complaintJsonToXlsx,
  userActivityJsonToXlsx,
} from '../../utilities/functions/xlsExportHelper';
import {
  ReportTypeEnum,
  IreportParameters,
  IactivityRestaurantPayload,
  IactivityUsersPayload,
} from '../../redux/reports/interfaces';
import { generatePdf } from '../../utilities/functions/helperFunctions';
import { USERTYPE } from './../../resources/enums';
import {
  GET_NEW_REGISTRATIONS_REPORT_REQUEST,
  GET_NEW_REGISTRATIONS_REPORT_SUCCESS,
  GET_COMPLAINTS_REPORT_REQUEST,
  GET_COMPLAINTS_REPORT_SUCCESS,
  GET_REPORT_PARAMETERS_SUCCESS,
  GET_REPORT_PARAMETERS_REQUEST,
  GET_FOOD_PARAMETERS_REPORT_REQUEST,
  GET_FOOD_PARAMETERS_REPORT_SUCCESS,
  GET_RESTAURANT_ACTIVITY_REPORT_FAILURE,
  GET_RESTAURANT_ACTIVITY_REPORT_REQUEST,
  GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS,
  GET_RESTAURANT_ACTIVITY_XLSX_REQUEST,
  GET_RESTAURANT_ACTIVITY_XLSX_FAILURE,
  GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS,
  GET_NEW_REGISTRATIONS_XLSX_REQUEST,
  GET_NEW_REGISTRATIONS_XLSX_SUCCESS,
  GET_NEW_REGISTRATIONS_XLSX_FAILURE,
  GET_COMPLAINTS_XLSX_REQUEST,
  GET_COMPLAINTS_XLSX_SUCCESS,
  GET_COMPLAINTS_XLSX_FAILURE,
  RESET_RESTAURANT_ACTIVITY_LIST,
  GET_NEW_REGISTRATIONS_REPORT_FAILURE,
  GET_COMPLAINTS_REPORT_FAILURE,
  GET_ALL_ACTIVITY_REPORT_REQUEST,
  GET_ALL_ACTIVITY_REPORT_FAILURE,
  GET_ALL_ACTIVITY_REPORT_SUCCESS,
  GET_USERS_ACTIVITY_REPORT_FAILURE,
  GET_USERS_ACTIVITY_REPORT_REQUEST,
  GET_USERS_ACTIVITY_REPORT_SUCCESS,
  RESET_USERS_ACTIVITY_LIST,
  GET_FOOD_PARAMETERS_REPORT_FAILURE,
  ACTIVITY_BY_RESTAURANT_REQUEST,
  ACTIVITY_BY_RESTAURANT_SUCCESS,
  ACTIVITY_BY_RESTAURANT_FAILURE,
  GET_USER_ACTIVITY_XLSX_REQUEST,
  GET_USER_ACTIVITY_XLSX_SUCCESS,
  GET_USER_ACTIVITY_XLSX_FAILURE,
  GET_PDF_FAILURE,
  GET_PDF_REQUEST,
  GET_PDF_SUCCESS,
} from './types';
import { AppState, AppThunk } from './../store/index';

const DUMMY_REPORT_PARAMETERS: IreportParameters = {
  newRegistrations: [
    {
      id: 'RESTAURANT',
      label: 'Éttermi regisztrációk',
      type: 'radio',
      name: 'filterRegistration',
      defaultChecked: true,
      // childrens: [
      //   { id: 'unclosed', label: 'Lezáratlanok', type: 'checkbox', defaultChecked: true },
      //   { id: 'closed', label: 'Lezáratlak', type: 'checkbox', defaultChecked: true },
      // ],
    },
    {
      id: 'USER',
      label: 'Felhasználói regisztrációk',
      type: 'radio',
      name: 'filterRegistration',
    },
  ],
  activity: [
    { id: 'all', label: 'Összes', type: 'radio', defaultChecked: true, name: 'activity' },
    { id: 'restaurants', label: 'Éttermek', type: 'radio', name: 'activity' },
    { id: 'users', label: 'Felhasználók', type: 'radio', name: 'activity' },
  ],
  complaints: [
    {
      id: 'restaurantClosed',
      label: 'Bezárt a szolgáltató a rendelésem átvétele előtt',
      type: 'checkbox',
      defaultChecked: true,
      checked: true,
    },
    {
      id: 'notReceivedOrderReceivedOtherFood',
      label: 'Nem tudtam azt a rendelést átvenni, amit rendeltem – más ételt vettem át helyette',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'notReceivedOrderReceivedNothing',
      label: 'Nem tudtam azt a rendelést átvenni, amit rendeltem – nem vettem át semmit',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'incompleteReceivedNothing',
      label: 'Hiányos, amit rendeltem – nem vettem át semmit',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'incompleteReceivedOrder',
      label: 'Hiányos, amit rendeltem – átvettem',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'foodNotTheSame',
      label: 'Nem azt tartalmazza az étel, ami a rendelésnél szerepel',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'orderPriceIsNotValid',
      label: 'Nem annyi az eredeti ára az ételnek, mint ami fel van tüntetve',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'foodIsNotLookALike',
      label: 'Az étel nem hasonlít ahhoz, mint ami fel van tüntetve',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'other',
      label: 'Egyéb (szabadszavas)',
      type: 'checkbox',
      checked: false,
    },
    {
      id: 'spoiledFood',
      label: 'Romlott az étel, amit átvettem',
      type: 'checkbox',
    },
  ],
};

export const fetchReportParameters = (): AppThunk => (dispatch): void => {
  dispatch({
    type: GET_REPORT_PARAMETERS_REQUEST,
  });
  const res = {
    message: 'Success',
    data: DUMMY_REPORT_PARAMETERS,
  };
  dispatch({
    type: GET_REPORT_PARAMETERS_SUCCESS,
    payload: res,
  });
};

export const fetchNewRegistrationsReport = (
  from: number,
  to: number,
  type: USERTYPE | string,
): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_NEW_REGISTRATIONS_REPORT_REQUEST,
  });

  try {
    const response = await client.getNewRegistrationsReport(from, to, type);

    dispatch({
      type: GET_NEW_REGISTRATIONS_REPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_NEW_REGISTRATIONS_REPORT_FAILURE,
      error: error,
    });
  }
};
export const fetchAllActivityReport = (from: number, to: number): AppThunk => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch({
    type: GET_ALL_ACTIVITY_REPORT_REQUEST,
  });

  try {
    const response = await client.getAllActivityReports(from, to);
    const {
      cashFlow,
      offerCount,
      successfulPaymentCount,
      sumPaymentCount,
      unsuccessfulPaymentCount,
    } = response.data;

    const payload = [
      { label: 'Feltöltések', value: offerCount },
      { label: 'Sikeres vásárlások', value: successfulPaymentCount },
      { label: 'Sikertelen vásárlások', value: unsuccessfulPaymentCount },
      { label: 'Összes vásárlás', value: sumPaymentCount },
      { label: 'Forgalom (HUF)', value: cashFlow },
    ];

    dispatch({
      type: GET_ALL_ACTIVITY_REPORT_SUCCESS,
      payload,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_ACTIVITY_REPORT_FAILURE,
      error: error,
    });
  }
};

export const fetchComplaintsReport = (
  from: number,
  to: number,
  type: string[],
  restaurantId: string,
): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_COMPLAINTS_REPORT_REQUEST,
  });
  try {
    const response = await client.getComplaintsReport(from, to, type, restaurantId);

    dispatch({
      type: GET_COMPLAINTS_REPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPLAINTS_REPORT_FAILURE,
      error: error,
    });
  }
};

export const fetchFoodParametersreport = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: GET_FOOD_PARAMETERS_REPORT_REQUEST,
  });
  try {
    const response = await client.getFoodParametersReport();
    dispatch({
      type: GET_FOOD_PARAMETERS_REPORT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_FOOD_PARAMETERS_REPORT_FAILURE,
      error: error,
    });
  }
};

export const getUsersActivityReport = (from: number, to: number, page: number): AppThunk => async (
  dispatch: Dispatch,
  getState: () => AppState,
): Promise<void> => {
  dispatch({
    type: GET_USERS_ACTIVITY_REPORT_REQUEST,
  });
  try {
    const {
      user: oldUsers,
      numberOfRegisteredUsers: oldUserCount,
      isLastPage: oldIsLastPage,
    } = getState().reports.activity;

    if (oldUsers.length <= page) {
      const columns = [
        { Header: 'Felhasználó neve', accessor: 'userName' },
        { Header: 'Sikeres vásárlások', accessor: 'completedOrdersCount' },
        { Header: 'Sikertelen vásárlások', accessor: 'failedOrdersCount' },
        { Header: 'Összes vásárlás', accessor: 'allOrdersCount' },
        { Header: 'Forgalom (HUF)', accessor: 'cashFlow' },
      ];
      const response = await client.fetchUsersActivityReports(from, to, page);

      const constructedObj: IactivityUsersPayload = {
        isLastPage: response.data.isLastPage,
        numberOfRegisteredUsers: response.data.numberOfRegisteredUsers,
        user: oldUsers.concat([
          {
            data: response.data.users,
            columns,
          },
        ]),
      };
      dispatch({
        type: GET_USERS_ACTIVITY_REPORT_SUCCESS,
        payload: constructedObj,
      });
    } else {
      const constructedObj: IactivityUsersPayload = {
        isLastPage: oldIsLastPage,
        numberOfRegisteredUsers: oldUserCount,
        user: oldUsers,
      };

      dispatch({
        type: GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS,
        payload: constructedObj,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USERS_ACTIVITY_REPORT_FAILURE,
      error,
    });
  }
};

export const getRestaurantActivityReport = (
  from: number,
  to: number,
  page: number,
): AppThunk => async (dispatch: Dispatch, getState): Promise<void> => {
  dispatch({
    type: GET_RESTAURANT_ACTIVITY_REPORT_REQUEST,
  });

  try {
    const {
      restaurant: oldRestaurants,
      restaurantCount: oldRestaurantCount,
      isLastPage: oldIsLastPage,
    } = getState().reports.activity;

    if (oldRestaurants.length <= page) {
      const response = await client.fetchRestaurantActivityReports(from, to, page);
      const columns = [
        { Header: 'Étterem neve', accessor: 'name' },
        { Header: 'Feltöltések', accessor: 'offersCount' },
        { Header: 'Sikeres vásárlások', accessor: 'successfulOrdersCount' },
        { Header: 'Sikertelen vásárlások', accessor: 'rejectedOrdersCount' },
        { Header: 'Összes vásárlás', accessor: 'ordersCount' },
        { Header: 'Forgalom (HUF)', accessor: 'makings' },
      ];

      const constructedObj: IactivityRestaurantPayload = {
        isLastPage: response.data.isLastPage,
        restaurantCount: response.data.restaurantCount,
        restaurant: oldRestaurants.concat([
          {
            data: response.data.restaurants,
            columns,
          },
        ]),
      };

      dispatch({
        type: GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS,
        payload: constructedObj,
      });
    } else {
      const constructedObj: IactivityRestaurantPayload = {
        isLastPage: oldIsLastPage,
        restaurantCount: oldRestaurantCount,
        restaurant: oldRestaurants,
      };

      dispatch({
        type: GET_RESTAURANT_ACTIVITY_REPORT_SUCCESS,
        payload: constructedObj,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RESTAURANT_ACTIVITY_REPORT_FAILURE,
      error: error,
    });
  }
};

export const resetRestaurantActivity = (from: number, to: number): AppThunk => async (
  dispatch,
  getState: () => AppState,
): Promise<void> => {
  dispatch({
    type: RESET_RESTAURANT_ACTIVITY_LIST,
  });

  getRestaurantActivityReport(from, to, 0)(dispatch, getState, null);
};

export const resetUsersActivity = (from: number, to: number): AppThunk => async (
  dispatch,
  getState: () => AppState,
): Promise<void> => {
  dispatch({
    type: RESET_USERS_ACTIVITY_LIST,
  });

  getUsersActivityReport(from, to, 0)(dispatch, getState, null);
};

export const getReportsByRestaurant = (
  from: number,
  to: number,
  restaurantId: string,
): AppThunk => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: ACTIVITY_BY_RESTAURANT_REQUEST,
  });
  try {
    const response = await client.fetchRestaurantReportByRestaurant(from, to, restaurantId);

    const tableObject = response.data.orders
      .map(
        (order: {
          userName: any;
          price: any;
          paymentDate: string | number | Date;
          quantity: any;
          cart: any[];
        }) => {
          const carts = order.cart.flatMap((cart) => ({
            ...cart,
            price: cart.price.discountPrice,
            userName: order.userName,
            paymentDate: new Date(order.paymentDate).toDateString(),
          }));
          return carts;
        },
      )
      .flat();

    const sumMakings = tableObject.reduce((acc: number, cV: any) => {
      return acc + cV.price * cV.quantity;
    }, 0);

    const columns = [
      { Header: 'Tétel', accessor: 'name' },
      { Header: 'Idopont', accessor: 'paymentDate' },
      { Header: 'Tétel értéke', accessor: 'price' },
      { Header: 'Darabszám', accessor: 'quantity' },
    ];

    dispatch({
      type: ACTIVITY_BY_RESTAURANT_SUCCESS,
      payload: {
        data: tableObject,
        columns: columns,
        additionalData: {
          sumMakings,
          ordersCount: response.data.orders.length,
        },
      },
    });
  } catch (e) {
    dispatch({
      type: ACTIVITY_BY_RESTAURANT_FAILURE,
    });
  }
};

export const getRestaurantActivityXlsx = (from: number, to: number): AppThunk => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch({
    type: GET_RESTAURANT_ACTIVITY_XLSX_REQUEST,
  });

  try {
    const response = await client.fetchRestaurantActivityXlsxData(from, to);
    const data = response.data.filter((restaurant) => restaurant.orders.length);
    await restaurantJsonToXlsx(from, to, data);
    dispatch({
      type: GET_RESTAURANT_ACTIVITY_XLSX_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_RESTAURANT_ACTIVITY_XLSX_FAILURE,
      error: error,
    });
  }
};

export const getUserActivityXlsx = (from: number, to: number): AppThunk => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch({
    type: GET_USER_ACTIVITY_XLSX_REQUEST,
  });

  try {
    const response = await client.fetchUsersActivityReports(from, to, 0, true);
    await userActivityJsonToXlsx(from, to, response.data.users);
    dispatch({
      type: GET_USER_ACTIVITY_XLSX_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ACTIVITY_XLSX_FAILURE,
      error: error,
    });
  }
};

export const getComplaintsXlsx = (from: number, to: number): AppThunk => async (
  dispatch: Dispatch,
  getState: () => AppState,
): Promise<void> => {
  dispatch({
    type: GET_COMPLAINTS_XLSX_REQUEST,
  });

  try {
    const response = await client.fetchComplaintXlsxData(from, to);
    await complaintJsonToXlsx(
      from,
      to,
      response.data,
      getState().reports.parameters[ReportTypeEnum.COMPLAINTS],
    );
    dispatch({
      type: GET_COMPLAINTS_XLSX_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_COMPLAINTS_XLSX_FAILURE,
      error: error,
    });
  }
};

export const getNewRegistartionsXlsx = (
  from: number,
  to: number,
  type: USERTYPE,
): AppThunk => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  dispatch({
    type: GET_NEW_REGISTRATIONS_XLSX_REQUEST,
  });

  try {
    const response = await client.getNewRegistrationsReport(from, to, type);
    const data = {
      restaurants:
        type === USERTYPE.RESTAURANT ? response.data : getState().reports.newRegistrations,
      users: type === USERTYPE.USER ? response.data : getState().reports.newRegistrations,
    };
    await registrationJsonToXlsx(from, to, data);

    dispatch({
      type: GET_NEW_REGISTRATIONS_XLSX_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_NEW_REGISTRATIONS_XLSX_FAILURE,
      error: error,
    });
  }
};

export const getAllReports = (): AppThunk => async (dispatch, getState): Promise<void> => {
  fetchFoodParametersreport()(dispatch, getState, null);
};

export const getPdf = (id: string, from: number, to: number): AppThunk => async (
  dispatch,
): Promise<void> => {
  dispatch({
    type: GET_PDF_REQUEST,
  });

  try {
    await generatePdf(id, from, to);

    dispatch({
      type: GET_PDF_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_PDF_FAILURE,
      error,
    });
  }
};
